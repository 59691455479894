<template>
  <div class="mobile-menu">
    <div class="mobile-menu__top">
      <div class="logo">
        <a href="/">
          <!-- <img
            src="/logo.svg"
            alt="svg"
          > -->
          FIN<span>S</span>OKOL
        </a>
      </div>
      <span
        class="mobile-menu__close"
        @click="$emit('close')"
      />
    </div>
    <div class="mobile-menu__content">
      <ul
        v-if="isAuth"
        class="mobile-menu__user-list"
      >
        <li>
          <a
            href="/lk"
            @click.prevent="openPage()"
          >
            <span class="mobile-menu__icon">
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.0002 2.00003L16.5902 0.590027L10.2502 6.93003L11.6602 8.34003L18.0002 2.00003ZM22.2402 0.590027L11.6602 11.17L7.48016 7.00003L6.07016 8.41003L11.6602 14L23.6602 2.00003L22.2402 0.590027ZM0.410156 8.41003L6.00016 14L7.41016 12.59L1.83016 7.00003L0.410156 8.41003Z"
                />
              </svg>
            </span>
            Подписка
          </a>
        </li>
        <li>
          <a
            href="/lk"
            @click.prevent="openPage()"
          >
            <span class="mobile-menu__icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 4C7.58457 4 4 7.58457 4 12C4 16.4154 7.58457 20 12 20C16.4154 20 20 16.4154 20 12C20 7.58457 16.4154 4 12 4ZM2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12Z"
                />
                <path
                  d="M12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 18.5C9.5 18.5 7.29 16.94 6 15C6.03 13.01 10 12 12 12C13.99 12 17.97 13.01 18 15C16.71 16.94 14.5 18.5 12 18.5Z"
                />
              </svg>
            </span>
            Профиль
          </a>
        </li>
        <li>
          <a
            href="/lk"
            @click.prevent="openPage()"
          >
            <span class="mobile-menu__icon">
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 4V2H8V4H12ZM2 6V17H18V6H2ZM18 4C19.11 4 20 4.89 20 6V17C20 18.11 19.11 19 18 19H2C0.89 19 0 18.11 0 17L0.00999999 6C0.00999999 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0H12C13.11 0 14 0.89 14 2V4H18Z"
                />
              </svg>
            </span>
            Документы
          </a>
        </li>
        <li>
          <a
            href="/lk"
            @click.prevent="openPage()"
          >
            <span class="mobile-menu__icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.01 2H18V14H3.17157L2.00269 15.1689L2.01 2ZM0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 20Z"
                />
              </svg>
            </span>
            Обратная связь
          </a>
        </li>
        <li>
          <a
            href="/lk"
            @click.prevent="logout"
          >
            <span class="mobile-menu__icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.09 12.59L8.5 14L13.5 9L8.5 4L7.09 5.41L9.67 8H0V10H9.67L7.09 12.59ZM16 0H2C0.89 0 0 0.9 0 2V6H2V2H16V16H2V12H0V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
                  fill="black"
                />
              </svg>
            </span>
            Выход
          </a>
        </li>
      </ul>
      <ul
        v-if="!isAuth"
        class="mobile-menu__user-list"
      >
        <li>
          <a
            class="mobile-menu__auth"
            href="/login"
          >
            <Icon
              type="account"
              to="white"
            />
            Личный кабинет
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "./mobile-menu.scss";
import { changePath, logout } from "@/utils/commonFuncs";
import scrollTo from "@/helpers/scrollTo";

export default {
  name: "MobileMenu",
  data() {
    return {
      menu: [
        {
          name: "Как получить",
          anchor: "how-get",
        },
        {
          name: "Условия",
          anchor: "conditions",
        },
        {
          name: "Вопрос/ответ",
          anchor: "faq",
        },
      ],
      calculator: 10000,
    };
  },
  computed: {
    isAuth() {
      return false;
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  beforeCreate() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    logout,
    openPage() {
      changePath("lk");
    },
    goToAnchor(anchor) {
      this.$emit("close");
      this.$nextTick(() => {
        scrollTo(`#${anchor}`);
      });
    },
  },
};
</script>
